import { useGlobalStore } from '../../store/store.js';

export default class ModificationFilter {
    static getFilters() {
        return useGlobalStore().activeUsersAndSupplierUsers.map(user => ({
            value: `${user.parent_user_id || user.id}`,
            text: user.fullName,
        }));
    }

    static apply(events, filters) {
        if (!filters.length) {
            return events;
        }

        return events.filter(event => {
            return filters.some(filter => filter == event.updated_by);
        });
    }
}
