import { get } from 'lodash-es';
import i18n from '../../plugins/vue-i18n.js';
import { getIconMarkup } from '../../utils/icon.js';
import TaskEventType from '../../entities/TaskEventType.js';
import TaskEventTypeIcon from '../../entities/TaskEventTypeIcon.js';

export default class EventFilter {
    static getFilters() {
        return [
            {
                value: 'call',
                text: i18n.t('calendar.filters.eventTypes.call'),
                icon: TaskEventTypeIcon.CALL,
            },
            {
                value: 'email',
                text: i18n.t('calendar.filters.eventTypes.email'),
                icon: TaskEventTypeIcon.EMAIL,
            },
            {
                value: 'appointment',
                text: i18n.t('calendar.filters.eventTypes.appointment'),
                icon: TaskEventTypeIcon.APPOINTMENT,
            },
            {
                value: 'phone_appointment',
                text: i18n.t('calendar.filters.eventTypes.phoneAppointment'),
                icon: getIconMarkup('regular/phone-landline', { class: 'event-icon' }),
            },
            {
                value: 'delivery',
                text: i18n.t('calendar.filters.eventTypes.delivery'),
                icon: TaskEventTypeIcon.DELIVERY,
            },
            {
                value: 'other',
                text: i18n.t('calendar.filters.eventTypes.other'),
                icon: TaskEventTypeIcon.OTHER,
            },
            {
                value: 'csi',
                text: i18n.t('calendar.filters.eventTypes.csi'),
                icon: TaskEventTypeIcon.CSI,
            },
            {
                value: 'sms',
                text: i18n.t('calendar.filters.eventTypes.sms'),
                icon: TaskEventTypeIcon.SMS,
            },
        ];
    }

    static apply(events, filters) {
        if (!filters.length) {
            return events;
        }

        return events.filter(event => {
            let isValid = false;

            filters.forEach(filter => {
                switch (filter) {
                    case 'call':
                        if (get(event, 'task_event_type_id') == TaskEventType.CALL) {
                            isValid = true;
                        }
                        break;

                    case 'email':
                        if (get(event, 'task_event_type_id') == TaskEventType.EMAIL) {
                            isValid = true;
                        }
                        break;

                    case 'appointment':
                        if (get(event, 'task_event_type_id') == TaskEventType.APPOINTMENT) {
                            isValid = true;
                        }
                        break;

                    case 'phone_appointment':
                        if (get(event, 'task_event_type_id') == TaskEventType.PHONE_APPOINTMENT) {
                            isValid = true;
                        }
                        break;

                    case 'delivery':
                        if (get(event, 'task_event_type_id') == TaskEventType.DELIVERY) {
                            isValid = true;
                        }
                        break;

                    case 'other':
                        if (get(event, 'task_event_type_id') == TaskEventType.OTHER) {
                            isValid = true;
                        }
                        break;

                    case 'csi':
                        if (get(event, 'task_event_type_id') == TaskEventType.CSI) {
                            isValid = true;
                        }
                        break;

                    case 'sms':
                        if (get(event, 'task_event_type_id') == TaskEventType.SMS) {
                            isValid = true;
                        }
                        break;
                }
            });

            return isValid;
        });
    }
}
