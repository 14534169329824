<template>
    <div class="flex items-center justify-between px-2" :class="{ 'py-2': !slim }">
        <div class="w-40 font-semibold flex-shrink-0" v-if="!slim">
            {{ $t(`calendar.filters.${name}`) }}
        </div>
        <activix-multiselect
            class="flex-1 min-w-0"
            label="text"
            identifier="value"
            :selected="selected"
            :options="options"
            :multiple="true"
            :placeholder="$t(`calendar.filters.${name}`)"
            :limit-text="limitText"
            :searchable="false"
            :size="slim ? 'mini' : 'medium'"
            :clearable="true"
            :white="white"
            :disabled="disabled"
            @update="updateSelected"
        />
    </div>
</template>

<script>
    import { mapState } from 'pinia';
    import CalendarFilterObject from '../../value-objects/Calendar/CalendarFilterObject.js';
    import { useGlobalStore } from '../../store/store.js';

    export default {
        name: 'CalendarFilter',

        props: {
            name: {
                type: String,
                default: '',
            },
            filters: {
                type: Array,
                default: () => [],
            },
            slim: {
                type: Boolean,
                default: false,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            white: {
                type: Boolean,
                default: false,
            },
        },

        computed: {
            ...mapState(useGlobalStore, ['activeUsers']),

            selected() {
                return this.filters || [];
            },

            options() {
                switch (this.name) {
                    case 'event':
                        return CalendarFilterObject.getEvents();

                    case 'leadType':
                        return CalendarFilterObject.getLeadTypes();

                    case 'role':
                        return CalendarFilterObject.getRoles();

                    case 'status':
                        return CalendarFilterObject.getStatus();

                    case 'division':
                        return CalendarFilterObject.getDivisions();

                    case 'childAccount':
                        return CalendarFilterObject.getChildAccounts();

                    case 'user':
                        return CalendarFilterObject.getUsers();

                    case 'automated':
                        return CalendarFilterObject.getAutomatedFilters();

                    case 'createdBy':
                        return CalendarFilterObject.getCreatedBy();

                    case 'updatedBy':
                        return CalendarFilterObject.getUpdatedBy();

                    default:
                        return [];
                }
            },
        },

        methods: {
            updateSelected(value) {
                this.$emit('update', value);
            },

            limitText() {
                return `${this.$t(`calendar.filters.${this.name}`)} (${this.selected.length})`;
            },
        },
    };
</script>
