<template>
    <activix-modal
        size="xs"
        :opened="opened"
        @close="close"
        @closed="onClosed"
    >
        <template slot="header">
            <div class="flex items-baseline justify-center">
                <h4 class="modal-title">
                    {{ $t('calendar.modals.view.title') }}
                </h4>
            </div>
        </template>

        <template slot="body">
            <div class="form-group text-center py-3">
                <label for="view-name">{{ $t('calendar.modals.view.name') }}</label>
                <input
                    type="text"
                    name="view-name"
                    class="form-control"
                    v-model="name"
                />
            </div>
            <div class="text-red-500 text-center" v-if="!hasUniqueName">
                {{ $t('calendar.uniqueCalendarViewNameWarning') }}
            </div>
        </template>

        <template slot="footer">
            <activix-button @click="close">
                {{ $t('general.cancel') }}
            </activix-button>
            <activix-button type="primary" :disabled="name.trim().length == 0 || !hasUniqueName" @click="saveView">
                {{ $t('general.save') }}
            </activix-button>
        </template>
    </activix-modal>
</template>

<script>
    import { mapState } from 'pinia';
    import { useGlobalStore } from '../../store/store.js';

    export default {
        props: {
            opened: {
                type: Boolean,
                required: true,
            },
            calendarView: {
                type: Object,
                default: null,
            },
        },

        data() {
            return {
                name: '',
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['parentAuthUser']),

            hasUniqueName() {
                if (!this.parentAuthUser.calendar_views) {
                    return true;
                }

                const isUnique = this.parentAuthUser.calendar_views.find(calendarView => {
                    return (
                        calendarView.name.trim() == this.name.trim() &&
                        (!this.calendarView || calendarView.id != this.calendarView.id)
                    );
                });

                return !isUnique;
            },
        },

        watch: {
            opened(newValue) {
                if (!newValue || !this.calendarView) {
                    return;
                }

                this.name = this.calendarView.name;
            },
        },

        methods: {
            close() {
                this.$emit('update:opened', false);
            },

            saveView() {
                this.$emit('calendar-view-saved', this.name);
                this.close();
            },

            onClosed() {
                this.name = '';
            },
        },
    };
</script>
