<template>
    <div class="flex items-center">
        <activix-tooltip :content="generateTooltip(key)" :key="key" v-for="(filter, key) in selectedFilters">
            <calendar-filter
                :name="key"
                :filters="filter"
                :slim="true"
                :white="true"
                :disabled="!empty(disabledFiltersObject[key])"
                :key="key"
                @update="applyFilters(key, $event)"
            />
        </activix-tooltip>
    </div>
</template>

<script>
    import { cloneDeep, debounce } from 'lodash-es';
    import CalendarFilter from './CalendarFilter.vue';
    import CalendarFilterObject from '../../value-objects/Calendar/CalendarFilterObject.js';

    export default {
        name: 'CalendarFilterLabelsList',

        components: {
            CalendarFilter,
        },

        props: {
            filters: {
                type: Object,
                default: () => {
                    return {};
                },
            },
            disabledFiltersObject: {
                type: Object,
                default: () => {
                    return {};
                },
            },
        },

        computed: {
            selectedFilters() {
                if (!Object.keys(this.formatedFilters).length) {
                    return null;
                }

                const filters = {};

                Object.keys(this.formatedFilters).forEach(key => {
                    if (this.disabledFiltersObject[key]) {
                        filters[key] = this.disabledFiltersObject[key][key];
                    } else if (this.formatedFilters[key].length) {
                        filters[key] = this.formatedFilters[key];
                    }
                });

                return filters;
            },

            formatedFilters() {
                return CalendarFilterObject.getFormatedFilters(this.filters);
            },
        },

        methods: {
            generateTooltip(key) {
                let tooltip = this.$t(`calendar.filters.${key}`);

                if (!empty(this.disabledFiltersObject[key])) {
                    tooltip += `<br><em>${this.disabledFiltersObject[key].tooltipText}</em>`;
                }

                return tooltip;
            },

            applyFilters(key, filterUpdated) {
                const filters = cloneDeep(this.filters);

                filters[key] = filterUpdated.map(filter => {
                    return filter.value;
                });

                this.$emit('update:filters', filters);
            },
        },

        mounted() {
            this.applyFilters = debounce(this.applyFilters, 750);
        },
    };
</script>
