<template>
    <div class="flex flex-col">
        <warning
            icon="regular/calendar"
            :title="$t('error.warning')"
            :content="$t('error.selectAccountWarning')"
            v-if="!validAccount"
        />
        <template v-else>
            <portal to="content-header" v-if="parentAuthUser.calendar_views.length">
                <view-selector
                    class="ml-3 lg:ml-0"
                    data-intercom-target="calendar.viewSelector"
                    :views="parentAuthUser.calendar_views"
                    :current-view="calendarView"
                    :selected-view="selectedCalendarView"
                    @update-view="onUpdateView"
                    @delete-view="onDeleteView"
                />
            </portal>

            <portal to="content-header-left">
                <activix-reload
                    :class="{ 'ml-3': mdLayout }"
                    :loading="isLoading"
                    @click="refetch"
                    v-if="!$browser.isMobileWebView()"
                />

                <activix-tooltip :content="$t('calendar.saveCalendarView')">
                    <div class="flex ml-3 | lg:ml-2" data-intercom-target="calendar.createView">
                        <icon
                            name="regular/floppy-disk-1"
                            class="link-grey"
                            :class="{ 'text-xl': mdLayout }"
                            @click="modals.calendarViewOpened = true"
                            v-if="!calendarView"
                        />
                    </div>
                </activix-tooltip>
            </portal>

            <portal to="content-header-right">
                <div class="flex items-center h-full">
                    <calendar-filter-labels-list
                        :filters.sync="filters"
                        :disabled-filters-object="disabledFiltersObject"
                        v-if="!lgLayout"
                    />

                    <calendar-filter-menu
                        :class="{ 'text-xl': mdLayout }"
                        :filters.sync="filters"
                        :disabled-filters-object="disabledFiltersObject"
                    />
                </div>
            </portal>

            <div class="box | flex-auto flex flex-col mb-4" :class="{ loading: isLoading }">
                <div class="box-body | flex-1" :class="{ 'px-3 py-4': lgLayout }">
                    <activix-calendar
                        :config="calendarConfigReactive"
                        :event-render="eventRender"
                        :events="getEvents"
                        ref="calendar"
                        @event-after-render="onEventAfterRender"
                        @event-click="onEventClick"
                        @event-destroy="onEventDestroy"
                        @event-drop="onEventDrop"
                        @view-render="onViewRender"
                        @view-destroy="onViewDestroy"
                        @view-button-click="onViewButtonClick"
                    />
                </div>
            </div>
            <div class="flex justify-between" :class="{ 'px-2': lgLayout }">
                <span @click="toggleShowLegend" v-if="lgLayout">
                    <icon class="link-grey text-xl mr-1" name="regular/information-circle" />
                </span>
                <transition name="fade">
                    <div
                        class="flex items-center px-2"
                        style="animation-duration: 0.25s"
                        v-if="showLegend || !lgLayout"
                    >
                        <div class="flex flex-wrap items-center">
                            <template v-if="useDefaultColorScheme">
                                <div class="flex items-center mr-3">
                                    <icon name="bold/sign-badge-circle" class="text-blue-500 mr-1" />
                                    {{ $t('divisions.new') }}
                                </div>
                                <div class="flex items-center mr-3">
                                    <icon name="bold/sign-badge-circle" class="text-grey-600 mr-1" />
                                    {{ $t('divisions.used') }}
                                </div>
                                <div class="flex items-center mr-3">
                                    <icon name="bold/sign-badge-circle" class="text-orange-500 mr-1" />
                                    {{ $t('divisions.service') }}
                                </div>
                                <div class="flex items-center mr-3">
                                    <icon name="bold/sign-badge-circle" class="text-purple-600 mr-1" />
                                    {{ $t('divisions.none') }}
                                </div>
                                <div class="flex items-center">
                                    <icon name="bold/sign-badge-circle" class="opacity-25 text-blue-500 mr-px" />
                                    <icon name="bold/sign-badge-circle" class="opacity-25 text-grey-600 mr-px" />
                                    <icon name="bold/sign-badge-circle" class="opacity-25 text-orange-500 mr-px" />
                                    <icon name="bold/sign-badge-circle" class="opacity-25 text-purple-600 mr-1" />
                                    {{ $t('calendar.filters.statusTypes.completed') }}
                                </div>
                            </template>
                            <template v-else>
                                <div class="flex items-center mr-3">
                                    <icon name="bold/sign-badge-circle" class="text-grey-600 mr-1" />
                                    {{ $t('calendar.task') }}
                                </div>
                                <div class="flex items-center mr-3">
                                    <icon name="bold/sign-badge-circle" class="text-blue-500 mr-1" />
                                    {{ $t('calendar.filters.eventTypes.appointment') }}
                                </div>
                                <div class="flex items-center mr-3">
                                    <icon name="bold/sign-badge-circle" class="text-green-500 mr-1" />
                                    {{ $t('calendar.filters.eventTypes.delivery') }}
                                </div>
                                <div class="flex items-center mr-3">
                                    <icon name="bold/sign-badge-circle" class="text-purple-600 mr-1" />
                                    {{ $t('calendar.filters.eventTypes.other') }}
                                </div>
                                <div class="flex items-center mr-3">
                                    <icon name="bold/sign-badge-circle" class="opacity-25 text-grey-600 mr-px" />
                                    <icon name="bold/sign-badge-circle" class="opacity-25 text-blue-500 mr-px" />
                                    <icon name="bold/sign-badge-circle" class="opacity-25 text-green-500 mr-px" />
                                    <icon name="bold/sign-badge-circle" class="opacity-25 text-purple-600 mr-1" />
                                    {{ $t('calendar.filters.statusTypes.completed') }}
                                </div>
                                <div class="flex items-center">
                                    <icon name="regular/sign-badge-circle" class="text-grey-600 mr-px" />
                                    <icon name="regular/sign-badge-circle" class="text-blue-500 mr-px" />
                                    <icon name="regular/sign-badge-circle" class="text-green-500 mr-px" />
                                    <icon name="regular/sign-badge-circle" class="text-purple-600 mr-1" />
                                    {{ $t('calendar.filters.statusTypes.uncompletedPast') }}
                                </div>
                            </template>
                        </div>
                    </div>
                </transition>
                <div class="flex items-center justify-end">
                    <div class="mx-4">
                        <activix-tooltip :content="$t('calendar.toggleColorScheme')">
                            <activix-switcher
                                class="flex"
                                data-intercom-target="calendar.colorSwitch"
                                size="small"
                                :value="!useDefaultColorScheme"
                                @click.native.prevent="toggleColorScheme"
                            />
                        </activix-tooltip>
                    </div>
                    <div class="whitespace-nowrap">
                        {{ $t('clientCard.total') }}: {{ visibleEventsCount }}
                    </div>
                </div>
            </div>

            <call-lead name="calendar:callLead" />
            <email-lead name="calendar:emailLead" />
            <calendar-view
                :opened.sync="modals.calendarViewOpened"
                :filters="filters"
                :calendar-view="selectedCalendarView"
                @calendar-view-saved="saveCalendarView"
                @closed="selectedCalendarView = null"
            />
            <automated-event-modal :show.sync="modals.showUpdateFutureEvent" :payload="pendingPayload" @save="save" />

            <activix-confirm-modal
                type="error"
                :content="$t('calendar.deleteCalendarViewConfirmation')"
                :opened.sync="modals.deleteCalendarViewModalOpened"
                @approve="triggerDeleteCalendarView"
                @closed="selectedCalendarView = null"
            />

            <activix-confirm-modal
                type="warning"
                :opened.sync="modals.colorSchemeOpened"
                :content="colorSchemeModalText"
                :title="$t('calendar.modals.colorScheme.title')"
                @approve="useDefaultColorScheme = false"
            />

            <filters-in-url
                :save-in-cache="!$route.query.viewId"
                :cache-key="cacheKey"
                :cache-exceptions="['date', 'viewId']"
                :filters.sync="filters"
                :disabled-filters-object="disabledFiltersObject"
                ref="filtersInUrl"
            />
        </template>
    </div>
</template>

<script>
    // Utils
    import { cloneDeep, debounce, forEach, get, isEqual } from 'lodash-es';
    import { mapState } from 'pinia';
    import { getIconMarkup } from '../../utils/icon.js';
    import { merge } from '../../utils/index.js';
    import { isMd } from '../../utils/layout.js';

    // Components
    import CalendarFilterLabelsList from '../../components/calendar/CalendarFilterLabelsList.vue';
    import CalendarFilterMenu from '../../components/calendar/CalendarFilterMenu.vue';
    import CalendarView from '../../components/modals/CalendarView.vue';
    import CallLead from '../../components/modals/CallLead.vue';
    import EmailLead from '../../components/modals/EmailLead.vue';
    import FiltersInUrl from '../../components/utils/FiltersInUrl.js';
    import ViewSelector from '../../components/ViewSelector.vue';
    import Warning from '../../components/Warning.vue';

    // Mixins
    import TrackView from '../../mixins/TrackView.js';
    import CalendarMixin from '../../mixins/Calendar.js';
    import AutomatedEventModal from '../../components/modals/AutomatedEventModal.vue';

    // Entities
    import Division from '../../entities/Division.js';
    import Role from '../../entities/Role.js';

    // Value Objects
    import TaskEventFormatter from '../../value-objects/Calendar/TaskEventFormatter.js';
    import CalendarFilterObject from '../../value-objects/Calendar/CalendarFilterObject.js';

    // Pinia
    import { useCalendarStore } from '../../store/modules/calendar/store.js';
    import { useContextStore } from '../../store/modules/context/store.js';
    import { useGlobalStore } from '../../store/store.js';

    let formatedEvents = [];
    let filteredEvents = [];

    export default {
        name: 'Calendar',

        components: {
            AutomatedEventModal,
            CalendarFilterLabelsList,
            CalendarFilterMenu,
            CalendarView,
            CallLead,
            EmailLead,
            FiltersInUrl,
            ViewSelector,
            Warning,
        },

        mixins: [TrackView, CalendarMixin],

        data() {
            return {
                showLegend: false,
                useDefaultColorScheme: true,
                visibleEventsCount: 0,
                fetchedDates: [],
                updateAutomatedSiblings: null,
                refreshNeeded: false,
                calendarConfig: {
                    defaultDate: null,
                    views: {
                        week: {
                            navLinkDayClick: date => {
                                this.$wait.start('calendar.rerender');
                                this.$refs.calendar.fireMethod('changeView', 'agendaDay', date);
                                this.postMessageToWorker({
                                    action: 'rerender',
                                    currentView: this.currentView,
                                    events: filteredEvents,
                                    useDefaultColorScheme: this.useDefaultColorScheme,
                                });
                            },
                        },
                        agendaWeek: {
                            allDaySlot: false,
                            slotEventOverlap: true,
                        },
                    },
                },
                currentDateRange: {
                    start: '',
                    end: '',
                },
                previousView: null,
                selectedCalendarView: null,
                modals: {
                    calendarViewOpened: false,
                    deleteCalendarViewModalOpened: false,
                    colorSchemeOpened: false,
                    showUpdateFutureEvent: false,
                },
                schedules: [],
                mobileAppMessageListener: null,
                debouncedEvents: [],
                updateDebounce: null,
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['parentAuthUser', 'taskEventToBeCompleted', 'authUser']),
            ...mapState(useContextStore, {
                contextAccount: 'account',
                contextGroup: 'group',
            }),
            ...mapState(useCalendarStore, ['calendarView']),

            calendarConfigReactive() {
                return {
                    defaultView: this.defaultView,
                    firstDay: this.$i18n.locale == 'en' ? 0 : 1,
                    header: {
                        right: this.calendarViews,
                        ...this.calendarConfig.header,
                    },
                    ...this.calendarConfig,
                };
            },

            calendarViews() {
                let calendarViews = isMd ? 'threeDay,agendaDay addEvent' : 'basicWeek,agendaDay';

                if (!isMd && !this.contextAccount.children.length) {
                    calendarViews = `month,${calendarViews}`;
                }

                return calendarViews;
            },

            defaultView() {
                const defaultView = isMd ? 'threeDay' : 'month';
                const defaultFilters = { view: this.contextAccount.children.length ? 'toDo' : defaultView };
                const storedFilters = this.contextAccount
                    ? this.$ls.get(`filters:calendar:${this.contextAccount.id}`, defaultFilters)
                    : defaultFilters;

                return storedFilters.view == 'threeDay' && !isMd ? defaultFilters.view : storedFilters.view;
            },

            parentAccountMode() {
                return this.contextAccount.children.length > 0;
            },

            cacheKey() {
                return `calendar:${this.contextAccount.id}`;
            },

            validAccount() {
                return empty(this.contextGroup);
            },

            isLoading() {
                return (
                    this.$wait.is(['fetching.contextAccount', this.currentlyFetching]) ||
                    this.$wait.is('calendar.rerender')
                );
            },

            currentlyFetching() {
                return `fetching.tasks.${this.currentDateRange.start}.${this.currentDateRange.end}`;
            },

            colorSchemeModalText() {
                return `${this.$t('calendar.modals.colorScheme.description')} <div class="mt-3">${this.$t(
                    'calendar.modals.colorScheme.proceed',
                )}</div>`;
            },

            limitCalendarTaskAccess() {
                return (
                    !this.authUser.isAdmin() &&
                    !this.authUser.isDirector() &&
                    !this.authUser.isBdcDirector() &&
                    this.authUser.custom_permissions.limit_calendar_task_access
                );
            },

            freezableUsers() {
                if (this.limitCalendarTaskAccess) {
                    return [this.authUser.id];
                }

                return this.getUsersFromFilters();
            },

            disabledFiltersObject() {
                if (!this.limitCalendarTaskAccess) {
                    return {};
                }

                return {
                    user: {
                        keys: this.freezableUsers,
                        user: [{ value: `${this.authUser.id}` }],
                        tooltipText: this.$t('calendar.limitedAccessToTasks'),
                    },
                };
            },

            isInAgendaView() {
                return ['agendaWeek', 'agendaDay'].includes(this.currentView);
            },

            matchingView() {
                if (this.currentView == 'agendaWeek') {
                    return 'basicWeek';
                }

                if (this.currentView == 'toDo') {
                    return 'agendaDay';
                }

                return this.currentView;
            },

            showViewModeOption() {
                return ['basicWeek', 'agendaDay'].includes(this.matchingView);
            },

            userAvailableSchedule() {
                if (this.currentView != 'agendaWeek' || this.filters.user.length != 1) {
                    return [];
                }

                const [filteredUserId] = this.filters.user;

                return this.schedules
                    .filter(schedule => schedule.user_id == filteredUserId)
                    .map(schedule => {
                        let weekDay = schedule.weekday - 1;
                        if (this.$i18n.locale == 'en') {
                            weekDay = schedule.weekday == 7 ? 0 : schedule.weekday;
                        }

                        const startAt = locale_dt(
                            `${this.currentDateRange.start} ${schedule.start_time}`,
                            'YYYY-MM-DD HH:mm:ss',
                            this.contextAccount.timezone,
                        ).weekday(weekDay);
                        const endAt = locale_dt(
                            `${this.currentDateRange.start} ${schedule.end_time}`,
                            'YYYY-MM-DD HH:mm:ss',
                            this.contextAccount.timezone,
                        ).weekday(weekDay);

                        if (startAt.isStartOfDay() && endAt.isStartOfDay()) {
                            endAt.endOfDay();
                        }

                        return {
                            start: startAt.iso(),
                            end: endAt.iso(),
                            id: 1,
                            rendering: 'inverse-background',
                        };
                    });
            },
        },

        watch: {
            async parentAccountMode(newValue, oldValue) {
                if (newValue == oldValue) {
                    return;
                }

                await this.$nextTick();
                this.$eventBus.$emit('reset-calendar');

                this.syncViewOptions();

                if (newValue) {
                    this.filters.view = 'toDo';
                }
            },

            async 'modals.showUpdateFutureEvent'(newValue) {
                if (newValue == false) {
                    this.updateAutomatedSiblings = null;
                }
                if (newValue == true) {
                    this.updateAutomatedSiblings = 'none';
                }
            },

            async 'contextAccount.id'() {
                this.setInitialFilters();

                await this.$nextTick();

                if (!this.calendarView) {
                    this.$refs.filtersInUrl.setFilters();
                } else {
                    this.setCalendarViewFromQuery();
                }

                this.refetch();
            },

            async filters(newValue, oldValue) {
                if (isEqual(newValue, oldValue)) {
                    return;
                }

                const shouldRefetch = Object.keys(newValue).some(key => {
                    return ['user', 'event'].includes(key) && !isEqual(newValue[key], oldValue[key]);
                });

                const shouldFetch = Object.keys(newValue).some(key => {
                    return ['date', 'view'].includes(key) && !isEqual(newValue[key], oldValue[key]);
                });

                await this.$nextTick();

                if (shouldRefetch) {
                    this.refetch();
                } else if (shouldFetch) {
                    await this.fetch();
                } else {
                    this.applyFilters();
                }
            },

            'filters.date'(newValue) {
                this.calendarConfig.defaultDate = newValue;

                if (this.$refs.calendar) {
                    this.$refs.calendar.fireMethod('gotoDate', newValue);
                }
            },

            'filters.view'(newValue) {
                this.setBaseView(newValue);
                this.currentView = newValue;
            },

            '$route.query.viewId'(newValue) {
                if (!newValue) {
                    useCalendarStore().calendarView = null;
                    return;
                }

                this.setCalendarViewFromQuery();
            },

            currentView: {
                immediate: true,
                async handler() {
                    if (!this.currentView) {
                        return;
                    }

                    await this.$nextTick();

                    this.syncViewOptions();
                },
            },

            useDefaultColorScheme(newValue) {
                this.$ls.set('calendar.defaultColorScheme', newValue);

                this.addEvents({ events: filteredEvents });
            },

            isLoading(newValue) {
                this.$browser.mobileApp.postMessage('isLoading', newValue);
            },
        },

        methods: {
            toggleColorScheme() {
                if (this.useDefaultColorScheme) {
                    this.modals.colorSchemeOpened = true;
                } else {
                    this.useDefaultColorScheme = true;
                }
            },

            toggleShowLegend() {
                this.showLegend = !this.showLegend;
            },

            syncViewOptions() {
                const $matchingViewButton = $(`.fc-${this.matchingView}-button`, this.$refs.calendar.$el);
                $matchingViewButton
                    .addClass('active')
                    .siblings()
                    .removeClass('active');

                this.syncViewModeOption();
            },

            getEvents(start, end, timezone, callback) {
                const visibleEvents = this.getEventsVisible();

                callback(visibleEvents);
            },

            getEventsVisible(start = null, end = null) {
                const eventLimit = this.currentView == 'month' ? 10 : 1000;
                const viewStart = start ? start.format('YYYY-MM-DD') : this.currentDateRange.start;
                const viewEnd = end ? end.format('YYYY-MM-DD') : this.currentDateRange.end;
                const days = {};

                const visibleEvents = filteredEvents.filter(event => {
                    const eventStart = event.start.substr(0, 10);
                    const eventEnd = event.end.substr(0, 10);
                    const eventInView =
                        (['agendaDay', 'toDo'].includes(this.currentView) && eventStart == viewStart) ||
                        (eventStart >= viewStart && eventStart < viewEnd) ||
                        (eventEnd > viewStart && eventEnd <= viewEnd) ||
                        (eventStart <= viewStart && eventEnd >= viewEnd);

                    if (!eventInView) {
                        return false;
                    }

                    if (!days[eventStart]) {
                        days[eventStart] = 0;
                    }

                    days[eventStart]++;

                    return days[eventStart] <= eventLimit + 1; // One more to display the "Show more..."
                });

                visibleEvents.push(...this.userAvailableSchedule);

                this.visibleEventsCount = Object.keys(days).reduce((count, day) => {
                    return count + days[day];
                }, 0);

                return visibleEvents;
            },

            refetch() {
                this.resetCalendarData();
                this.fetchSchedules();
                this.fetch();
            },

            setInitialFilters() {
                if (!this.validAccount) {
                    return;
                }

                const filterAlreadyApplied = !!this.$ls.get(`filters:${this.cacheKey}`);
                const userIsInAccount = this.authUser.account_id == this.contextAccount.id;

                if (
                    this.filters.user &&
                    !filterAlreadyApplied &&
                    userIsInAccount &&
                    !this.authUser.isAdmin() &&
                    !Role.hasDirectorRights(this.authUser.role_id)
                ) {
                    this.filters.user = [`${this.authUser.id}`];
                }
            },

            async fetch(date, buffer = null) {
                if (!this.validAccount) {
                    return;
                }

                if (!date) {
                    date = this.filters.date;
                }

                const currentDate = this.getCurrentDate(date, buffer);
                const startDate = this.getStartDate(currentDate, buffer);
                const endDate = this.getEndDate(currentDate, buffer);
                const missingDates = this.getMissingDates(startDate, endDate);
                const filterEvents = cloneDeep(this.filters).event;

                if (filterEvents.length > 0) {
                    const allEventIndex = filterEvents.indexOf('all');

                    if (allEventIndex !== -1) {
                        delete filterEvents[allEventIndex];
                    }
                }

                if (missingDates.length) {
                    this.$wait.start(`fetching.tasks.${startDate.toDateString()}.${endDate.toDateString()}`);

                    this.addFetchedDates(startDate, endDate);

                    const response = await this.$axios.get('v1/task-events', {
                        params: {
                            accountId: this.contextAccount.id,
                            startDate: startDate.toDateString(),
                            endDate: endDate.toDateString(),
                            taskEventTypes: filterEvents,
                            users: this.freezableUsers,
                        },
                    });

                    this.addEvents({
                        events: response.data.data,
                        buffer,
                        startDate: startDate.toDateString(),
                        endDate: endDate.toDateString(),
                    });
                }

                if (!buffer) {
                    this.fetch(this.filters.date, 'before');
                    this.fetch(this.filters.date, 'after');
                }
            },

            onViewRender(view) {
                this.currentView = view.type;
                this.currentDateRange = {
                    start: view.start.format('YYYY-MM-DD'),
                    end: view.end.subtract(1, 'day').format('YYYY-MM-DD'),
                };

                this.$nextTick(() => {
                    const currentDate = this.getCurrentDate(view.start);
                    const startDate = this.getStartDate(currentDate);
                    const endDate = this.getEndDate(currentDate);

                    const missingDates = this.getMissingDates(startDate, endDate);

                    if (missingDates.length) {
                        this.resetCalendarData();
                        this.$refs.calendar.fireMethod('removeEvents');
                    } else {
                        this.rerenderEvents();
                    }

                    this.getEventsVisible(startDate, endDate);

                    // "Hack" to prevent double watch trigger
                    this.filters = {
                        ...this.filters,
                        date: currentDate.toDateString(),
                        view: view.type,
                    };
                });
            },

            getDatesBetween(startDate, endDate) {
                const start = startDate.clone().setMidday();
                const end = endDate.clone().setMidday();
                const dates = [];

                do {
                    dates.push(start.toDateString());
                } while (end.diff(start.addDays()) >= 0);

                return dates;
            },

            getMissingDates(startDate, endDate) {
                const dates = this.getDatesBetween(startDate, endDate);

                return dates.filter(date => {
                    return !this.fetchedDates.includes(date);
                });
            },

            addFetchedDates(startDate, endDate) {
                const dates = this.getDatesBetween(startDate, endDate);

                this.fetchedDates = this.fetchedDates.concat(dates);
            },

            getStartDate(date) {
                const startDate = date.clone();

                if (this.currentView == 'month') {
                    return startDate.startOfMonth();
                }

                if (['agendaWeek', 'basicWeek'].includes(this.currentView)) {
                    return startDate.startOfWeek();
                }

                return startDate.setMidday();
            },

            getEndDate(date) {
                const endDate = date.clone();

                if (this.currentView == 'month') {
                    return endDate.endOfMonth();
                }

                if (['agendaWeek', 'basicWeek'].includes(this.currentView)) {
                    return endDate.endOfWeek();
                }

                if (this.currentView == 'threeDay') {
                    return endDate.addDays(2);
                }

                return endDate.setMidday();
            },

            getCurrentDate(date, buffer = null) {
                const currentDate = locale_date(date);

                if (buffer == 'before') {
                    return this.subDates(currentDate, 1);
                }

                if (buffer == 'after') {
                    return this.addDates(currentDate, 1);
                }

                if (this.currentView == 'month') {
                    return currentDate.startOfMonth();
                }

                if (['agendaWeek', 'basicWeek'].includes(this.currentView)) {
                    return currentDate.startOfWeek();
                }

                return currentDate.setMidday();
            },

            addDates(date, qty) {
                const clonedDate = date.clone();

                switch (this.currentView) {
                    case 'month':
                        return clonedDate.addMonths(qty);

                    case 'agendaWeek':
                    case 'basicWeek':
                        return clonedDate.addWeeks(qty);

                    case 'agendaDay':
                    case 'basicDay':
                        return clonedDate.addDays(qty);

                    case 'threeDay':
                        return clonedDate.addDays(qty * 3);
                }

                return clonedDate;
            },

            subDates(date, qty) {
                const clonedDate = date.clone();

                switch (this.currentView) {
                    case 'month':
                        return clonedDate.subMonths(qty);

                    case 'agendaWeek':
                    case 'basicWeek':
                        return clonedDate.subWeeks(qty);

                    case 'agendaDay':
                    case 'basicDay':
                        return clonedDate.subDays(qty);

                    case 'threeDay':
                        return clonedDate.subDays(qty * 3);
                }

                return clonedDate;
            },

            resetCalendarData() {
                this.fetchedDates = [];

                formatedEvents = [];
                filteredEvents = [];
            },

            findEvent(eventId) {
                return filteredEvents.find(e => e.id == eventId);
            },

            applyFilters() {
                this.postMessageToWorker({
                    action: 'filter',
                    currentView: this.currentView,
                    events: formatedEvents,
                });
            },

            addEvents({ events, ...args }) {
                this.postMessageToWorker({
                    action: 'format',
                    currentView: this.currentView,
                    events,
                    useDefaultColorScheme: this.useDefaultColorScheme,
                    ...args,
                });
            },

            addTaskEvent(event) {
                this.addEvents({ events: [event] });
            },

            editTaskEvent(event) {
                this.debouncedEvents.push(event);
                this.updateDebounce();
            },

            deleteTaskEvent(event) {
                const eventIndex = formatedEvents.findIndex(e => e.id == event.id);

                if (eventIndex !== -1) {
                    formatedEvents.splice(eventIndex, 1);
                    this.applyFilters();
                }
            },

            getUsersFromFilters() {
                return this.filters.user.filter(userId => {
                    return CalendarFilterObject.getUsers().some(filter => filter.value == userId);
                });
            },

            async rerenderEvents() {
                if (!this.$refs.calendar) {
                    return;
                }

                this.$refs.calendar.fireMethod('refetchEvents');

                await this.$nextTick();

                this.$wait.end('calendar.rerender');
            },

            setBaseView(view) {
                this.calendarConfig.defaultView = view;

                if (this.$refs.calendar) {
                    this.$refs.calendar.fireMethod('changeView', view);

                    this.onViewButtonClick();
                }
            },

            onTaskEventCreated(event) {
                this.addEvents({ events: [event] });
            },

            onTaskEventUpdated(event) {
                this.editTaskEvent(event);
            },

            onTaskEventDeleted(event) {
                this.deleteTaskEvent(event);
            },

            onAfterFormat({ events, ...args }) {
                const newEvents = events.filter(event => {
                    return !formatedEvents.some(e => e.id == event.id);
                });

                formatedEvents = formatedEvents.concat(newEvents);

                formatedEvents.forEach(formatedEvent => {
                    const updatedEvent = events.find(event => {
                        return event.id == formatedEvent.id && event.markup != formatedEvent.markup;
                    });

                    if (updatedEvent) {
                        formatedEvent.markup = updatedEvent.markup;
                    }
                });

                this.postMessageToWorker({
                    ...args,
                    action: 'filter',
                    currentView: this.currentView,
                    events: formatedEvents,
                });
            },

            onAfterFilter({ events, startDate, endDate }) {
                filteredEvents = events;

                this.$nextTick(() => {
                    if (
                        (this.currentDateRange.start == startDate && this.currentDateRange.end == endDate) ||
                        (!startDate && !endDate)
                    ) {
                        this.rerenderEvents();
                    }

                    this.$wait.end(`fetching.tasks.${startDate}.${endDate}`);
                });
            },

            onAfterRerender({ events }) {
                filteredEvents = events;

                this.rerenderEvents();
            },

            openCreateTaskEventModal() {
                this.$eventBus.$emit('open-add-task-event', {});
            },

            syncViewModeOption() {
                const $buttonsContainer = $('.fc-header-toolbar .fc-right', this.$refs.calendar.$el);
                $('.fc-toggleAgenda-button', $buttonsContainer).remove();

                if (!this.showViewModeOption) {
                    return;
                }

                const icon = this.isInAgendaView ? 'bold/align-left' : 'regular/align-left';
                const iconMarkup = getIconMarkup(icon);

                const $toggleViewModeButton = $(
                    `<button type="button" class="fc-toggleAgenda-button btn btn-default">${iconMarkup}</button>`,
                );
                $buttonsContainer.prepend($toggleViewModeButton);

                $toggleViewModeButton.click(this.onViewModeOptionClick);

                if (this.isInAgendaView) {
                    $toggleViewModeButton.addClass('active');

                    this.$tooltip.setContent($toggleViewModeButton, this.$t('calendar.toggleAgendaViewOff'));
                } else {
                    this.$tooltip.setContent($toggleViewModeButton, this.$t('calendar.toggleAgendaViewOn'));
                }
            },

            onViewButtonClick() {
                const previousViewType = get(this.previousView, 'type');
                const defaultViewMode = this.$ls.get('calendar.defaultViewMode', {});

                if (
                    (this.currentView == 'basicWeek' && defaultViewMode.week == 'agenda') ||
                    (this.currentView == 'agendaDay' && defaultViewMode.day == 'list')
                ) {
                    this.onViewModeOptionClick();
                }

                if (!previousViewType) {
                    return;
                }

                this.$wait.start('calendar.rerender');

                if (this.previousView.date.isSameMonth(now())) {
                    this.$refs.calendar.fireMethod('today');
                }

                if (
                    (['agendaWeek', 'threeDay', 'agendaDay'].includes(this.currentView) &&
                        !['agendaWeek', 'threeDay', 'agendaDay'].includes(previousViewType)) ||
                    (!['agendaWeek', 'threeDay', 'agendaDay'].includes(this.currentView) &&
                        ['agendaWeek', 'threeDay', 'agendaDay'].includes(previousViewType))
                ) {
                    this.addEvents({ events: filteredEvents });

                    return;
                }

                this.$wait.end('calendar.rerender');
            },

            onViewModeOptionClick(event = null) {
                const defaultViewMode = this.$ls.get('calendar.defaultViewMode', {});
                let newView;

                switch (this.currentView) {
                    case 'basicWeek':
                        newView = 'agendaWeek';
                        defaultViewMode.week = 'agenda';
                        break;
                    case 'agendaWeek':
                        newView = 'basicWeek';
                        defaultViewMode.week = 'list';
                        break;
                    case 'toDo':
                        newView = 'agendaDay';
                        defaultViewMode.day = 'agenda';
                        break;
                    case 'agendaDay':
                        newView = 'toDo';
                        defaultViewMode.day = 'list';
                        break;
                }

                this.$ls.set('calendar.defaultViewMode', defaultViewMode);

                if (event) {
                    event.currentTarget.blur();
                }

                this.$wait.start('calendar.rerender');
                this.$refs.calendar.fireMethod('changeView', newView, this.currentDateRange.start);

                this.postMessageToWorker({
                    action: 'rerender',
                    currentView: this.currentView,
                    events: filteredEvents,
                    useDefaultColorScheme: this.useDefaultColorScheme,
                });
            },

            onViewDestroy(view) {
                if (!this.currentDateRange.start) {
                    return;
                }

                this.previousView = {
                    date: locale_dt(view.start),
                    type: view.type,
                };
            },

            async saveCalendarView(name) {
                const filters = cloneDeep(this.filters);
                let calendarView;

                delete filters.date;

                if (this.parentAuthUser.isParentOf(this.authUser.id)) {
                    const indexUser = filters.user.findIndex(userId => userId == this.authUser.id);

                    filters.user[indexUser] = `${this.parentAuthUser.id}`;
                }

                const payload = {
                    name,
                    filters: JSON.stringify(filters),
                };

                if (this.selectedCalendarView) {
                    calendarView = await this.$api.calendarViews.update(this.selectedCalendarView.id, payload);
                    this.parentAuthUser.updateCalendarView(calendarView);
                } else {
                    payload.user_id = this.parentAuthUser.id;
                    calendarView = await this.$api.calendarViews.store(payload);
                    this.parentAuthUser.addCalendarView(calendarView);
                    this.updateQuery({ viewId: calendarView.id });
                }

                useCalendarStore().calendarView = calendarView;
            },

            setCalendarViewFromQuery() {
                const viewId = this.$route.query.viewId;

                if (!viewId) {
                    return;
                }

                const calendarView = cloneDeep(this.parentAuthUser.getCalendarView(viewId));

                if (!calendarView) {
                    return;
                }

                const filters = {};

                Object.keys(calendarView.filters).forEach(key => {
                    if (key == 'user' && this.parentAuthUser.isParentOf(this.authUser.id)) {
                        const indexUser = calendarView.filters.user.findIndex(
                            userId => userId == this.parentAuthUser.id,
                        );

                        calendarView.filters.user[indexUser] = `${this.authUser.id}`;
                    }

                    filters[key] = calendarView.filters[key];
                });

                filters.date = this.filters.date;

                if (this.lgLayout && ['month', 'week', 'agendaWeek', 'basicWeek'].includes(filters.view)) {
                    filters.view = 'threeDay';
                }

                this.filters = filters;

                useCalendarStore().calendarView = calendarView;
                this.$refs.filtersInUrl.updateQuery(this.filters);
            },

            unsetCalendarView() {
                const params = [];

                Object.keys(this.$route.query).forEach(key => {
                    if (key != 'viewId') {
                        params[key] = this.$route.query[key];
                    }
                });

                this.updateQuery(params);

                useCalendarStore().calendarView = null;
            },

            async triggerDeleteCalendarView() {
                const viewId = this.selectedCalendarView.id;

                await this.$api.calendarViews.destroy(viewId);
                this.parentAuthUser.deleteCalendarView(viewId);

                if (this.calendarView.id == viewId) {
                    this.unsetCalendarView();
                }
            },

            onUpdateView(view) {
                this.selectedCalendarView = view;
                this.modals.calendarViewOpened = true;
            },

            onDeleteView(view) {
                this.selectedCalendarView = view;
                this.modals.deleteCalendarViewModalOpened = true;
            },

            async fetchSchedules() {
                const divisionIds = [Division.NEW, Division.USED, Division.SERVICE, Division.FINANCE];
                const fetchCalls = divisionIds.map(divisionId => {
                    return this.$axios.get('v1/schedules', {
                        params: {
                            account_id: this.contextAccount.id,
                            division_id: divisionId,
                        },
                    });
                });

                const schedules = await Promise.all(fetchCalls);

                this.schedules = schedules.reduce((result, response) => result.concat(response.data.data), []);
            },
        },

        created() {
            this.$eventBus.$on('add-task-event', this.addTaskEvent);
            this.$eventBus.$on('delete-task-event', this.deleteTaskEvent);
            this.$eventBus.$on('task-event-created', this.onTaskEventCreated);
            this.$eventBus.$on('task-event-updated', this.onTaskEventUpdated);
            this.$eventBus.$on('task-event-deleted', this.onTaskEventDeleted);

            this.setInitialFilters();

            this.updateDebounce = debounce(() => {
                forEach(this.debouncedEvents, (event) => {
                    const existingEvent = formatedEvents.find(e => e.id == event.id);

                    if (existingEvent) {
                        merge(
                            existingEvent,
                            TaskEventFormatter.formatEvent(event, this.currentView, this.useDefaultColorScheme),
                        );
                    }
                });
                this.debouncedEvents = [];
                this.applyFilters();
            }, 5000, { maxWait: 10000 });
        },

        mounted() {
            this.mobileAppMessageListener = this.$browser.mobileApp.addMessageListener('refreshCalendar', this.refetch);
            this.$browser.mobileApp.postMessage('isOnCalendar', true);

            this.setCalendarViewFromQuery();
            this.useDefaultColorScheme = this.$ls.get('calendar.defaultColorScheme', true);

            this.$nextTick(() => {
                this.fetchSchedules();
                this.fetch();
            });
        },

        beforeDestroy() {
            if (this.mobileAppMessageListener) {
                this.mobileAppMessageListener.remove();
            }
            this.$browser.mobileApp.postMessage('isOnCalendar', false);

            this.resetCalendarData();
            useCalendarStore().calendarView = null;

            this.$eventBus.$off('add-task-event', this.addTaskEvent);
            this.$eventBus.$off('delete-task-event', this.deleteTaskEvent);
            this.$eventBus.$off('task-event-created', this.onTaskEventCreated);
            this.$eventBus.$off('task-event-updated', this.onTaskEventUpdated);
            this.$eventBus.$off('task-event-deleted', this.onTaskEventDeleted);
        },
    };
</script>
