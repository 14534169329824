import { useContextStore } from '../../store/modules/context/store.js';

export default class ChildAccountFilter {
    static getFilters() {
        return useContextStore().account.children.map(account => {
            return {
                value: `${account.id}`,
                text: `${account.name}`,
            };
        });
    }

    static apply(events, filters) {
        const validFilters = filters.filter(childAccountId => {
            return this.getFilters().some(filter => filter.value == childAccountId);
        });

        if (!validFilters.length) {
            return events;
        }

        return events.filter(event => {
            return validFilters.some(filter => {
                return event.lead?.account_id == filter || event.user?.account_id == filter;
            });
        });
    }
}
