<template>
    <el-popover
        placement="bottom"
        width="310"
        popper-class="mt-3"
        @click="visible = !visible"
        @hide="onHide"
        v-model="visible"
    >
        <div class="flex ml-3 mr-4 lg:mr-0" data-intercom-target="calendar.filter" slot="reference">
            <icon :class="isEmptyFilters ? 'link-grey' : 'link-blue'" name="bold/filter-2" />
        </div>
        <div class="bg-grey-200 border-0 border-grey-300 border-b flex items-center justify-between px-4 py-3">
            <span class="font-semibold" v-text="$t('calendar.filters.selectFilters')" />
            <activix-button
                type="white"
                size="small"
                :disabled="isEmptyFilters"
                @click="clearAll"
            >
                {{ $t('calendar.filters.clearAll') }}
            </activix-button>
        </div>
        <div class="flex flex-col p-4" :class="{ loading: $wait.is('loading.calendar') }">
            <activix-tooltip
                :content="!empty(disabledFiltersObject[index]) ? disabledFiltersObject[index].tooltipText : ''"
                :key="index"
                v-for="(selectedFilter, index) in selectedFilters"
            >
                <calendar-filter
                    :name="index"
                    :filters="
                        !empty(disabledFiltersObject[index]) ? disabledFiltersObject[index][index] : selectedFilter
                    "
                    :disabled="!empty(disabledFiltersObject[index])"
                    @update="updateFilters(index, $event)"
                />
            </activix-tooltip>
        </div>
        <div class="border-0 border-grey-300 border-t px-4 py-3 text-center">
            <activix-button
                type="default"
                size="small"
                class="mr-3"
                @click="close"
                v-if="mdLayout"
            >
                {{ $t('general.close') }}
            </activix-button>
            <activix-button type="primary" size="small" @click="applyFilters">
                {{ $t('calendar.filters.apply') }}
            </activix-button>
        </div>
    </el-popover>
</template>

<script>
    import { cloneDeep, forEach } from 'lodash-es';
    import { Wormhole } from 'portal-vue';
    import { mapState } from 'pinia';
    import CalendarFilter from './CalendarFilter.vue';
    import CalendarFilterObject from '../../value-objects/Calendar/CalendarFilterObject.js';
    import { useContextStore } from '../../store/modules/context/store.js';
    import { useGlobalStore } from '../../store/store.js';

    export default {
        name: 'CalendarFilterMenu',

        components: {
            CalendarFilter,
        },

        props: {
            filters: {
                type: Object,
                required: true,
            },
            disabledFiltersObject: {
                type: Object,
                default: () => {
                    return {};
                },
            },
        },

        data() {
            return {
                visible: false,
                selectedFilters: cloneDeep(this.formatedFilters),
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['parentAuthUser']),
            ...mapState(useContextStore, {
                contextAccount: 'account',
            }),

            isEmptyFilters() {
                let noFilterSelected = true;

                Object.keys(this.formatedFilters).forEach(key => {
                    if (this.formatedFilters[key].length) {
                        noFilterSelected = false;
                    }
                });

                return noFilterSelected;
            },

            formatedFilters() {
                const filters = cloneDeep(CalendarFilterObject.getFormatedFilters(this.filters));

                if (!this.contextAccount.account_manager) {
                    delete filters.childAccount;
                }

                return filters;
            },
        },

        watch: {
            formatedFilters: {
                immediate: true,
                handler(newValue) {
                    this.selectedFilters = cloneDeep(newValue);
                },
            },
        },

        methods: {
            applyFilters() {
                this.visible = false;
                const filters = cloneDeep(this.filters);

                if (this.contextAccount.account_manager && this.selectedFilters.childAccount) {
                    filters.childAccount = this.selectedFilters.childAccount;
                }

                Object.keys(this.selectedFilters).forEach(key => {
                    if (!this.selectedFilters.hasOwnProperty(key)) {
                        return;
                    }

                    filters[key] = this.selectedFilters[key].map(filter => {
                        return filter.value;
                    });
                });

                this.$emit('update:filters', filters);

                forEach(filters, (value, key) => {
                    if (value.length != 0 && !['date', 'view'].includes(key)) {
                        this.$behavior.track('Calendar', { action: 'filter', type: key, value });
                    }
                });
            },

            clearAll() {
                this.visible = false;
                const filters = cloneDeep(this.filters);

                Object.keys(filters).forEach(key => {
                    if (!CalendarFilterObject.defaultFilters.hasOwnProperty(key)) {
                        return;
                    }

                    filters[key] = CalendarFilterObject.defaultFilters[key];
                });

                this.$emit('update:filters', filters);
            },

            updateFilters(index, value) {
                this.selectedFilters[index] = value;
            },

            onHide() {
                if (!this.lgLayout) {
                    return;
                }

                // Hacky, but needed to prevent the popover to close when clicking inside the select picker modal (shown on top)
                if (Wormhole.hasContentFor('modal-select-picker')) {
                    this.visible = true;
                }
            },

            close() {
                this.visible = false;
            },
        },
    };
</script>
