import { mapActions, mapState } from 'pinia';

// Worker
import worker from '../workers/filter-task-events/index.js';

// Entities
import TaskEventType from '../entities/TaskEventType.js';
import Lead from '../entities/Lead.js';

import { isMd } from '../utils/layout.js';
import { useGlobalStore } from '../store/store.js';
import { useContextStore } from '../store/modules/context/store.js';

export default {
    data() {
        const config = {
            currentView: 'basicWeek',
            pendingPayload: {},
            calendarConfig: {
                columnHeaderFormat: isMd ? 'ddd D' : 'dddd',
                minTime: '06:00:00',
                maxTime: '23:00:00',
                editable: !this.guest,
                navLinks: true,
                defaultView: 'basicWeek',
                eventLimit: true,
                eventLimitClick: 'basicWeek',
                header: {
                    left: isMd ? 'prev,today,next' : 'prev,next today addEvent',
                    center: 'title',
                    right: isMd ? 'threeDay,agendaDay addEvent' : 'month,basicWeek,agendaDay',
                },
                bootstrapGlyphicons: {
                    addEvent: 'glyphicon-plus',
                },
                views: {
                    month: {
                        eventLimit: 10,
                        navLinkDayClick: date => {
                            this.$refs.calendar.fireMethod('changeView', 'basicWeek', date);
                        },
                    },
                    week: {
                        eventLimit: 1000,
                        columnHeaderFormat: 'ddd D',
                        slotEventOverlap: false,
                    },
                    day: {
                        eventLimit: 1000,
                        allDaySlot: false,
                    },
                    toDo: {
                        type: 'basicDay',
                        buttonText: 'toDo',
                    },
                    threeDay: {
                        type: 'agenda',
                        duration: { days: 3 },
                        buttonText: 'threeDay',
                        allDaySlot: false,
                    },
                },
            },
            filters: {
                event: [],
                leadType: [],
                status: [],
                division: [],
                role: [],
                childAccount: [],
                user: [],
                automated: [],
                date: now_date().toDateString(),
                view: 'basicWeek',
                createdBy: [],
                updatedBy: [],
            },
            modals: {
                showUpdateFutureEvent: false,
            },
            elementClicked: {
                element: null,
                clickCount: 0,
            },
        };

        if (isMd) {
            config.calendarConfig.titleFormat = 'MMM';
        }

        if (!this.$feature.isEnabled('new-lead-page', false)) {
            config.calendarConfig.customButtons = {
                addEvent: {
                    click: () => {
                        this.openCreateTaskEventModal();
                    },
                },
            };
        }

        return {
            ...config,
            lead: new Lead(),
        };
    },

    computed: {
        ...mapState(useGlobalStore, ['configs', 'parentAuthUser', 'guest']),
        context() {
            return useContextStore().$state;
        },
    },

    methods: {
        ...mapActions(useGlobalStore, ['taskEventUpdated', 'fetchLead', 'appendNewError', 'setTaskEventToBeCompleted']),

        eventRender(event) {
            return event.markup;
        },

        onEventAfterRender(event, element) {
            if (!element[0].dataset.tooltip) {
                return;
            }

            this.$tooltip.destroy(element);

            this.$nextTick(() => {
                this.$tooltip.init(element, element[0].dataset.tooltip);
            });
        },

        onEventDestroy(event, element) {
            if (!element[0].dataset.tooltip) {
                return;
            }

            this.$tooltip.destroy(element);
        },

        onEventDrop(event) {
            const payload = {
                start_at: locale_dt(event.start).toString(),
                end_at: locale_dt(event.end).toString(),
                draggable: true,
                view: this.currentview,
            };

            if (this.currentView != 'month') {
                payload.type = event.task_event_type_id;
            }

            payload.id = event.id;

            if (event.automation_action && event.automation_action.execution.occurence > 1) {
                this.modals.showUpdateFutureEvent = true;
                this.pendingPayload = payload;
                return;
            }

            this.save(payload);
        },

        save(payload) {
            this.pendingPayload = {};

            this.$axios
                .put(`v1/reminder/${payload.id}`, payload)
                .then(response => {
                    this.taskEventUpdated(response.data.data);
                })
                .catch(error => {
                    // @TODO Show error message and revert
                    this.appendNewError({
                        code: '0084',
                        display: false,
                        error,
                        payload,
                    });
                });
        },

        async onEventClick(event, element) {
            if (isMd) {
                if (!$(element.target).is(this.elementClicked.element)) {
                    this.elementClicked.element = element.target;
                    this.elementClicked.clickCount = 1;
                    return;
                }

                element.currentTarget._tippy.hide();

                this.elementClicked.element = null;
                this.elementClicked.clickCount = 0;
            }

            const targetElement = $(element.target).closest('.fc-custom-icon');
            const iconClicked = !!targetElement.length;
            const iconElement = targetElement.children('.event-icon');

            if (iconClicked) {
                if (event.lead_id) {
                    this.lead = await this.fetchLead(event.lead_id);
                } else {
                    this.lead = new Lead();
                }

                this.triggerAction(event, iconElement);
            } else {
                this.$eventBus.$emit('open-edit-task-event', {
                    taskEvent: event,
                });
            }
        },

        triggerAction(event, iconElement) {
            const eventFound = this.findEvent(event.id);

            if (this.guest || !event.lead_id || !eventFound) {
                return;
            }

            if ([TaskEventType.CALL, TaskEventType.EMAIL, TaskEventType.SMS].includes(event.task_event_type_id)) {
                this.setTaskEventToBeCompleted(event.id);
                this.openAction(event, iconElement);

                this.$behavior.track('Calendar', { action: 'quick-action', type: TaskEventType.getKey(event.task_event_type_id), lead: this.lead.id, location: 'calendar' });
            } else if (this.$route.name != 'leads.update') {
                const route = this.$router.resolve({
                    name: 'leads.update',
                    params: { id: event.lead_id },
                });

                window.open(route.href, '_blank');
            }
        },

        openAction(event, iconElement) {
            if (!this.lead.id) {
                return;
            }

            let popoverContent = '';

            switch (event.task_event_type_id) {
                case TaskEventType.CALL:
                    popoverContent = this.$t('modal.no_phone');

                    if (this.lead.unsubscribe_all_date || this.lead.unsubscribe_call_date) {
                        popoverContent = this.$t('clientCard.clientIsCallUnsubscribed');
                        break;
                    }

                    if (this.lead.lead_phones.length) {
                        this.$modal.show('calendar:callLead', this.lead.id);
                        return;
                    }

                    break;

                case TaskEventType.EMAIL:
                    popoverContent = this.$t('modal.no_email');

                    if (this.lead.unsubscribe_all_date || this.lead.unsubscribe_email_date) {
                        popoverContent = this.$t('clientCard.clientIsEmailUnsubscribed');
                        break;
                    }

                    if (this.lead.lead_emails.length) {
                        this.$modal.show('calendar:emailLead', this.lead.id);
                        return;
                    }

                    break;

                case TaskEventType.SMS:
                    popoverContent = this.$t('modal.no_phone');

                    if (this.lead.unsubscribe_all_date) {
                        popoverContent = this.$t('clientCard.clientIsSmsUnsubscribed');
                        break;
                    }

                    if (this.lead.lead_phones.length) {
                        this.$eventBus.$emit('open-sms-modal', { leadId: this.lead.id });
                        return;
                    }

                    break;
            }

            if (!iconElement) {
                return;
            }

            const popoverElement = iconElement.parent();

            popoverElement
                .popover({
                    placement: 'bottom',
                    trigger: 'manual',
                    container: 'body',
                    content: popoverContent,
                })
                .on('hidden.bs.popover', () => {
                    this.$nextTick(() => {
                        popoverElement.popover('destroy');
                    });
                })
                .popover('show');

            setTimeout(() => {
                popoverElement.popover('hide');
            }, 2000);
        },

        async postMessageToWorker(options) {
            const context = this.context;

            const workerPayload = await worker.send({
                ...{
                    filters: this.filters,
                    configs: this.configs,
                    buffer: null,
                    context,
                    parentAuthUser: this.parentAuthUser,
                },
                ...options,
            });

            switch (workerPayload.action) {
                case 'format':
                    this.onAfterFormat(workerPayload);
                    break;

                case 'filter':
                    this.onAfterFilter(workerPayload);
                    break;

                case 'rerender':
                    this.onAfterRerender(workerPayload);
            }
        },
    },
};
