import '../../custom.js';
import DivisionFilter from './DivisionFilter.js';
import EventFilter from './EventFilter.js';
import LeadTypeFilter from './LeadTypeFilter.js';
import RoleFilter from './RoleFilter.js';
import StatusFilter from './StatusFilter.js';
import ChildAccountFilter from './ChildAccountFilter.js';
import UserFilter from './UserFilter.js';
import CreationFilter from './CreationFilter.js';
import ModificationFilter from './ModificationFilter.js';
import AutomatedFilter from './AutomatedFilter.js';

export default class CalendarFilterObject {
    static get defaultFilters() {
        const defaultFilters = {
            event: [],
            leadType: [],
            status: [],
            division: [],
            role: [],
            childAccount: [],
            user: [],
            automated: [],
            createdBy: [],
            updatedBy: [],
        };

        return defaultFilters;
    }

    static get keys() {
        return [
            'event',
            'leadType',
            'role',
            'status',
            'division',
            'childAccount',
            'user',
            'automated',
            'createdBy',
            'updatedBy',
        ];
    }

    static get allFilters() {
        const filters = {};

        this.keys.forEach(key => {
            filters[key] = this.getFilterByKey(key);
        });

        return filters;
    }

    static getEvents() {
        return EventFilter.getFilters();
    }

    static getLeadTypes() {
        return LeadTypeFilter.getFilters();
    }

    static getRoles() {
        return RoleFilter.getFilters();
    }

    static getStatus() {
        return StatusFilter.getFilters();
    }

    static getDivisions() {
        return DivisionFilter.getFilters();
    }

    static getChildAccounts() {
        return ChildAccountFilter.getFilters();
    }

    static getUsers() {
        return UserFilter.getFilters();
    }

    static getAutomatedFilters() {
        return AutomatedFilter.getFilters();
    }

    static getCreatedBy() {
        return CreationFilter.getFilters();
    }

    static getUpdatedBy() {
        return ModificationFilter.getFilters();
    }

    static getFilterByKey(key) {
        switch (key) {
            case 'event':
                return this.getEvents();

            case 'leadType':
                return this.getLeadTypes();

            case 'role':
                return this.getRoles();

            case 'status':
                return this.getStatus();

            case 'division':
                return this.getDivisions();

            case 'childAccount':
                return this.getChildAccounts();

            case 'user':
                return this.getUsers();

            case 'automated':
                return this.getAutomatedFilters();

            case 'createdBy':
                return this.getCreatedBy();

            case 'updatedBy':
                return this.getUpdatedBy();

            default:
                return [];
        }
    }

    static getFormatedFilters(filters) {
        const validFilters = this.defaultFilters;

        Object.keys(filters).forEach(key => {
            if (!this.keys.includes(key)) {
                return;
            }

            const valuesToSearch = Object.values(filters[key]);

            for (const filter of this.allFilters[key]) {
                if (!valuesToSearch.includes(filter.value)) {
                    continue;
                }

                validFilters[key].push(filter);
            }
        });

        return validFilters;
    }
}
