import { empty, getFullName, getAccountName } from '../../utils/index.js';
import { getIconMarkup } from '../../utils/icon.js';

import TaskEventType from '../../entities/TaskEventType.js';
import TaskEventTypeIcon from '../../entities/TaskEventTypeIcon.js';
import Division from '../../entities/Division.js';
import ActivixDate from '../ActivixDate.js';

import i18n from '../../plugins/vue-i18n.js';
import { useContextStore } from '../../store/modules/context/store.js';
import { useGlobalStore } from '../../store/store.js';

export default class TaskEventFormatter {
    static accountHasGas() {
        return useContextStore().account.client_card_fields.process.gas;
    }

    static getStatusIcon(event) {
        let icon = '';

        if (event.no_show) {
            icon += getIconMarkup('regular/delete', { class: 'mr-1' });
        }

        return icon;
    }

    static getTaskIcon(event) {
        let icon = '';

        if (event.priority == 'high') {
            icon += getIconMarkup('bold/alert-triangle', { class: 'mr-1' });
        }

        if (event.task_event_type_id) {
            switch (event.task_event_type_id) {
                case TaskEventType.CALL:
                    icon += `
                        ${TaskEventTypeIcon.CALL}
                    `;
                    break;

                case TaskEventType.EMAIL:
                    icon += `
                        ${TaskEventTypeIcon.EMAIL}
                    `;
                    break;

                case TaskEventType.APPOINTMENT:
                    icon += TaskEventTypeIcon.APPOINTMENT;
                    break;

                case TaskEventType.PHONE_APPOINTMENT:
                    icon += getIconMarkup('regular/phone-landline', { class: 'event-icon' });
                    break;

                case TaskEventType.DELIVERY:
                    if (this.accountHasGas && event.lead && event.lead.gas) {
                        icon += getIconMarkup('regular/gas-load', { class: 'event-icon | mr-1' });
                    }

                    if (event.lead && event.lead.prepared) {
                        icon += getIconMarkup('regular/check-circle-1', { class: 'event-icon | mr-1' });
                    }

                    icon += TaskEventTypeIcon.DELIVERY;
                    break;

                case TaskEventType.OTHER:
                    icon += TaskEventTypeIcon.OTHER;
                    break;

                case TaskEventType.CSI:
                    icon += TaskEventTypeIcon.CSI;
                    break;

                case TaskEventType.SMS:
                    icon += TaskEventTypeIcon.SMS;
                    break;
            }
        }

        return icon;
    }

    static generateEventTooltip(event) {
        let tooltip = '';

        if (!event.lead) {
            return i18n.t('modal.no_lead');
        }

        let mainEvent = '';
        const startAt = new ActivixDate(event.start_at);
        const endAt = new ActivixDate(event.end_at);
        const commercial = event.lead && event.lead.commercial ? event.lead.commercial : null;

        const vehicle = event.lead?.sold_or_first_wanted_vehicle;
        const creator = event.automation_action_id ? i18n.t('createdMethods.activix') : getFullName(event.creator);
        const advisor = event.lead.user ? getFullName(event.lead.user) : null;

        if (event.main_event) {
            mainEvent = '*';
        }

        tooltip += `
            <div><span class='font-semibold'>${i18n.t('modal.title')}</span> : ${event.title + mainEvent}</div>
            <div><span class='font-semibold'>${i18n.t('modal.client')}</span> : ${getFullName(event.lead)}</div>
        `;

        if (!empty(vehicle) && !empty(vehicle.model) && !empty(vehicle.year)) {
            tooltip += `<div><span class='font-semibold'>${i18n.t('calendar.vehicle')}</span> : ${vehicle.model} ${
                vehicle.year
            }</div>`;
        }

        if (!empty(advisor)) {
            tooltip += `<div><span class='font-semibold'>${i18n.t('modal.user')}</span> : ${advisor}</div>`;
        }

        tooltip += `<div><span class='font-semibold'>${i18n.t('modal.createdBy')}</span> : ${creator} </div>`;

        if (!empty(event.description)) {
            tooltip += `<div><span class='font-semibold'>${i18n.t('modal.desc')}</span> : ${event.description.replace(
                /"/g,
                '&quot;',
            )}</div>`;
        }

        if (!empty(event.user)) {
            tooltip += `<div><span class='font-semibold'>${i18n.t('modal.task_owner')}</span> : ${getFullName(
                event.user,
            )}</div>`;
        }

        if (!empty(getAccountName(event))) {
            tooltip += `<div><span class='font-semibold'>${i18n.t('modal.account')}</span> : ${getAccountName(
                event,
            )}</div>`;
        }

        if (event.task_event_type_id == TaskEventType.DELIVERY && !empty(event.lead.user)) {
            tooltip += `<div><span class='font-semibold'>${i18n.t('clientCard.advisor')}</span> : ${getFullName(
                event.lead.user,
            )}</div>`;
        }

        if (!empty(commercial)) {
            tooltip += `<div><span class='font-semibold'>${i18n.t('calendar.commercial')}</span> : ${getFullName(
                commercial,
            )}</div>`;
        }

        if (
            ![TaskEventType.CALL, TaskEventType.EMAIL, TaskEventType.CSI, TaskEventType.SMS].includes(
                event.task_event_type_id,
            ) &&
            this.view == 'month'
        ) {
            tooltip += `
                <div><span class='font-semibold'>${i18n.t('modal.start_at')}</span> : ${startAt.toHumanTime()}</div>
                <div><span class='font-semibold'>${i18n.t('modal.end_at')}</span> : ${endAt.toHumanTime()}</div>
            `;
        }

        if (!empty(vehicle) && event.task_event_type_id == TaskEventType.DELIVERY && vehicle.stock) {
            tooltip += `<div><span class='font-semibold'>Stock</span> : ${vehicle.stock}</div>`;
        }

        if (event.task_event_type_id == TaskEventType.DELIVERY && !empty(event.lead.delivered_by)) {
            tooltip += `<div><span class='font-semibold'>${i18n.t('clientCard.deliveredBy')}</span> : ${getFullName(
                event.lead.delivered_by,
            )}</div>`;
        }

        tooltip = tooltip.replace(/"/g, "'");

        return `<div class='text-left'>${tooltip}</div>`;
    }

    static getEventClassName(event, useDefaultColorScheme) {
        if (!useDefaultColorScheme) {
            return this.getEventClassNameAlternateColor(event);
        }

        return this.getEventClassNameDefaultColor(event);
    }

    static getEventClassNameAlternateColor(event) {
        const classes = [];
        let backgroundColor = '';
        let borderColor = '';
        let textColor = '';

        switch (event.task_event_type_id) {
            case TaskEventType.CALL:
            case TaskEventType.EMAIL:
            case TaskEventType.SMS:
            case TaskEventType.CSI:
                backgroundColor = 'bg-grey-600';
                borderColor = 'border-grey-600';
                textColor = 'text-grey-600';
                break;

            case TaskEventType.APPOINTMENT:
                backgroundColor = 'bg-blue-500';
                borderColor = 'border-blue-500';
                textColor = 'text-blue-500';
                break;

            case TaskEventType.DELIVERY:
                backgroundColor = 'bg-green-500';
                borderColor = 'border-green-500';
                textColor = 'text-green-500';
                break;

            default:
                backgroundColor = 'bg-purple-600';
                borderColor = 'border-purple-600';
                textColor = 'text-purple-600';
                break;
        }

        classes.push(borderColor);

        if (event.status || event.canceled || event.no_show) {
            classes.push('opacity-50', backgroundColor);
        } else if (as_locale(event.start_at).isPast()) {
            classes.push('bg-white', textColor);
        } else {
            classes.push(backgroundColor);
        }

        if (event.canceled) {
            classes.push('line-through');
        }

        return classes.join(' ');
    }

    static getEventClassNameDefaultColor(event) {
        const classes = [];

        if (event.status || event.canceled || event.no_show) {
            classes.push('opacity-50');
        }

        if (event.lead) {
            switch (event.lead.division_id) {
                case Division.NEW:
                    classes.push('bg-blue-500');
                    break;

                case Division.USED:
                    classes.push('bg-grey-600');
                    break;

                case Division.SERVICE:
                    classes.push('bg-orange-500');
                    break;

                default:
                    classes.push('bg-purple-600');
                    break;
            }
        }

        if (event.canceled) {
            classes.push('line-through');
        }

        return classes.join(' ');
    }

    static eventIsDisabled(event) {
        if (
            (event.task_event_type_id == TaskEventType.OTHER && empty(event.lead)) ||
            (event.task_event_type_id != TaskEventType.OTHER &&
                (empty(event.lead) ||
                    !!event.lead.unsubscribe_all_date ||
                    (!!event.lead.unsubscribe_email_date && event.task_event_type_id == TaskEventType.EMAIL) ||
                    (!!event.lead.unsubscribe_call_date && event.task_event_type_id == TaskEventType.CALL) ||
                    (!!event.lead.unsubscribe_sms_date && event.task_event_type_id == TaskEventType.SMS)))
        ) {
            return true;
        }

        return !this.isEditable(event);
    }

    static generateTaskMarkup(event, currentView, useDefaultColorScheme) {
        if (['agendaWeek', 'threeDay', 'agendaDay'].includes(currentView)) {
            return TaskEventFormatter.generateAgendaTaskMarkup(event, useDefaultColorScheme);
        }

        return TaskEventFormatter.generateStandardTaskMarkup(event, useDefaultColorScheme);
    }

    static generateStandardTaskMarkup(event, useDefaultColorScheme) {
        const startAt = new ActivixDate(event.start_at);
        const tooltip = this.generateEventTooltip(event);
        const taskIcon = this.getTaskIcon(event);
        const statusIcon = this.getStatusIcon(event);
        const className = this.getEventClassName(event, useDefaultColorScheme);
        const isDisabled = this.eventIsDisabled(event);

        return `
            <a
                class="fc-event fc-start fc-end ${className}"
                data-tooltip="${tooltip}"
            >
                <div class="fc-content | flex items-center">
                    <div class="fc-time">
                        ${startAt.toHumanTime()}
                    </div>
                    <div class="fc-title | flex-1 truncate px-1">
                        ${event.title}
                    </div>
                    <div class="fc-custom-icon | flex items-center ${isDisabled ? 'disabled' : ''}">
                        ${statusIcon}
                        ${taskIcon}
                    </div>
                </div>
            </a>
        `;
    }

    static generateAgendaTaskMarkup(event, useDefaultColorScheme) {
        const startAt = new ActivixDate(event.start_at);
        const endAt = new ActivixDate(event.end_at);
        const tooltip = this.generateEventTooltip(event);
        const taskIcon = this.getTaskIcon(event);
        const statusIcon = this.getStatusIcon(event);
        const className = this.getEventClassName(event, useDefaultColorScheme);
        const isDisabled = this.eventIsDisabled(event);

        if (endAt.diffInMinutes(startAt) < 45) {
            return this.generateAgendaTaskMarkupSingleRow(event, useDefaultColorScheme);
        }

        let time = startAt.toHumanTime();

        if (endAt.isSameDay(startAt)) {
            time += ` - ${endAt.toHumanTime()}`;
        }

        return `
            <a
                class="fc-event fc-start fc-end | border border-grey-200 ${className}"
                data-tooltip="${tooltip}"
            >
                <div class="fc-content | flex items-center">
                    <div class="fc-title | flex-1 truncate">
                        ${event.title}
                    </div>
                    <div class="fc-custom-icon | flex items-center ${isDisabled ? 'disabled' : ''}">
                        ${statusIcon}
                        ${taskIcon}
                    </div>
                </div>
                <div class="fc-content | flex items-center">
                    <div class="fc-time | leading-none truncate">
                        ${time}
                    </div>
                </div>
            </a>
        `;
    }

    static generateAgendaTaskMarkupSingleRow(event, useDefaultColorScheme) {
        const startAt = new ActivixDate(event.start_at);
        const tooltip = this.generateEventTooltip(event);
        const taskIcon = this.getTaskIcon(event);
        const statusIcon = this.getStatusIcon(event);
        const className = this.getEventClassName(event, useDefaultColorScheme);
        const isDisabled = this.eventIsDisabled(event);

        return `
            <a
                class="fc-event fc-start fc-end | border border-grey-200 ${className}"
                data-tooltip="${tooltip}"
            >
                <div class="fc-content | flex items-center">
                    <div class="flex flex-1 items-center overflow-hidden">
                        <div class="fc-title | truncate">
                            ${event.title}
                        </div>
                        <div class="fc-time | px-1">
                            ${startAt.toHumanTime()}
                        </div>
                    </div>
                    <div class="fc-custom-icon | flex items-center ${isDisabled ? 'disabled' : ''}">
                        ${statusIcon}
                        ${taskIcon}
                    </div>
                </div>
            </a>
        `;
    }

    static formatEvent(event, currentView, useDefaultColorScheme) {
        const startAt = new ActivixDate(event.start_at);
        const endAt = new ActivixDate(event.end_at);

        return {
            ...event,
            markup: TaskEventFormatter.generateTaskMarkup(event, currentView, useDefaultColorScheme),

            // FullCalendar specific
            editable: this.isEditable(event),
            allDay: startAt.isStartOfDay(),
            start: startAt.toString(),
            end: endAt.toString(),
        };
    }

    static isEditable(event) {
        const authUser = useGlobalStore().authUser;
        return (
            authUser.isSameUser(event.user_id) ||
            authUser.isAdmin() ||
            authUser.isDirector() ||
            authUser.isCommercialDirector() ||
            authUser.isBdcDirector()
        );
    }
}
