import { cloneDeep, isEqual, isArray, debounce } from 'lodash-es';
import { merge, orderByKey } from '../../utils/index.js';

export default {
    render: () => null,

    props: {
        filters: {
            type: Object,
            required: true,
            twoWay: true,
        },
        saveInCache: {
            type: Boolean,
            default: true,
        },
        cacheKey: {
            type: String,
            default: '',
        },
        cacheExceptions: {
            type: [String, Array],
            default: '',
        },
        disabledFiltersObject: {
            type: Object,
            default: () => {
                return {};
            },
        },
    },

    watch: {
        filters: {
            deep: true,
            handler(newFilters) {
                this.updateQuery(newFilters);

                if (this.saveInCache) {
                    this.setCache(newFilters);
                }
            },
        },

        '$route.query': {
            deep: true,
            immediate: true,
            handler(newQuery, oldQuery) {
                if (this.saveInCache) {
                    this.setFilters(true, newQuery);
                    return;
                }

                this.setFilters(!oldQuery, newQuery, oldQuery);
            },
        },
    },

    methods: {
        setFilters(fromCache = true, newQuery = null, oldQuery = null) {
            const currentFilters = cloneDeep(this.filters);

            // Load from cache on initial load
            if (fromCache && this.saveInCache) {
                merge(currentFilters, this.getCache());
            }

            // Only update if filters changed
            if (newQuery) {
                const shouldUpdate = Object.keys(newQuery).some(key => {
                    return !oldQuery || (currentFilters.hasOwnProperty(key) && !isEqual(newQuery[key], oldQuery[key]));
                });

                if (shouldUpdate) {
                    merge(currentFilters, newQuery);
                }
            }

            Object.keys(this.disabledFiltersObject).forEach(filter => {
                currentFilters[filter] = [this.disabledFiltersObject[filter].keys.toString()];
            });

            this.$emit('update:filters', currentFilters);
        },

        updateQuery(filters) {
            const currentQuery = this.$route.query || {};
            const newQuery = cloneDeep(filters);

            // Keep existing query params that are not filters
            Object.keys(currentQuery).forEach(key => {
                if (typeof newQuery[key] === 'undefined') {
                    newQuery[key] = currentQuery[key];
                }
            });

            // Don't keep empty filters
            Object.keys(newQuery).forEach(key => {
                if (!newQuery[key]) {
                    delete newQuery[key];
                }
            });

            this.$router.replace({
                ...this.$route,
                query: orderByKey(newQuery),
            });
        },

        setCache(filters) {
            if (!this.cacheKey) {
                return;
            }

            const cacheExceptions = !isArray(this.cacheExceptions) ? [this.cacheExceptions] : this.cacheExceptions;
            const filtersToCache = {};

            Object.keys(filters).forEach(key => {
                if (!cacheExceptions.includes(key)) {
                    filtersToCache[key] = filters[key];
                }
            });

            this.$ls.set(`filters:${this.cacheKey}`, filtersToCache);
        },

        getCache() {
            if (!this.cacheKey) {
                return {};
            }

            return this.$ls.get(`filters:${this.cacheKey}`, {});
        },
    },

    created() {
        this.updateQuery = debounce(this.updateQuery, 100);
        this.setCache = debounce(this.setCache, 100);
    },
};
