import { get } from 'lodash-es';
import i18n from '../../plugins/vue-i18n.js';
import Role from '../../entities/Role.js';

export default class RoleFilter {
    static getFilters() {
        return [
            {
                value: 'advisor',
                text: i18n.t('database.role_advisor'),
            },
            {
                value: 'bdc',
                text: i18n.t('database.role_agent'),
            },
            {
                value: 'director',
                text: i18n.t('database.role_director'),
            },
            {
                value: 'directorBdc',
                text: i18n.t('database.role_director_bdc'),
            },
            {
                value: 'directorCommercial',
                text: i18n.t('database.role_commercial'),
            },
        ];
    }

    static apply(events, filters) {
        if (!filters.length) {
            return events;
        }

        return events.filter(event => {
            let isValid = false;

            filters.forEach(filter => {
                switch (filter) {
                    case 'advisor':
                        if (get(event, 'user.role_id') == Role.ADVISOR) {
                            isValid = true;
                        }
                        break;

                    case 'bdc':
                        if (get(event, 'user.role_id') == Role.AGENT) {
                            isValid = true;
                        }
                        break;

                    case 'director':
                        if (get(event, 'user.role_id') == Role.DIRECTOR) {
                            isValid = true;
                        }
                        break;

                    case 'directorBdc':
                        if (get(event, 'user.role_id') == Role.DIRECTOR_BDC) {
                            isValid = true;
                        }
                        break;

                    case 'directorCommercial':
                        if (get(event, 'user.role_id') == Role.COMMERCIAL) {
                            isValid = true;
                        }
                        break;
                }
            });

            return isValid;
        });
    }
}
