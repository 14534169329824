import { get } from 'lodash-es';
import i18n from '../../plugins/vue-i18n.js';

import Division from '../../entities/Division.js';
import { useGlobalStore } from '../../store/store.js';

export default class DivisionFilter {
    static getFilters() {
        if (useGlobalStore().authUser.isAdmin()) {
            return [
                {
                    value: 'new',
                    text: i18n.t('divisions.new'),
                },
                {
                    value: 'used',
                    text: i18n.t('divisions.used'),
                },
                {
                    value: 'service',
                    text: i18n.t('divisions.service'),
                },
                {
                    value: 'none',
                    text: i18n.t('divisions.none'),
                },
            ];
        }

        const divisions = [
            {
                value: 'new',
                text: i18n.t('divisions.new'),
            },
            {
                value: 'used',
                text: i18n.t('divisions.used'),
            },
        ];

        useGlobalStore().authUser.divisions.forEach(division => {
            if (division.id == Division.SERVICE) {
                divisions.push({
                    value: 'service',
                    text: i18n.t('divisions.service'),
                });
            }
        });

        divisions.push({
            value: 'none',
            text: i18n.t('divisions.none'),
        });

        return divisions;
    }

    static apply(events, filters) {
        if (!filters.length) {
            return events;
        }

        return events.filter(event => {
            let isValid = false;

            filters.forEach(filter => {
                switch (filter) {
                    case 'new':
                        if (get(event, 'lead.division_id') == Division.NEW) {
                            isValid = true;
                        }
                        break;

                    case 'used':
                        if (get(event, 'lead.division_id') == Division.USED) {
                            isValid = true;
                        }
                        break;

                    case 'service':
                        if (get(event, 'lead.division_id') == Division.SERVICE) {
                            isValid = true;
                        }
                        break;

                    case 'none':
                        if (!get(event, 'lead.division_id')) {
                            isValid = true;
                        }
                        break;
                }
            });

            return isValid;
        });
    }
}
