import { get } from 'lodash-es';
import LeadType from '../../entities/LeadType.js';
import i18n from '../../plugins/vue-i18n.js';

export default class LeadTypeFilter {
    static getFilters() {
        return [
            {
                value: 'email',
                text: i18n.t('database.lead_type_email'),
            },
            {
                value: 'phone',
                text: i18n.t('database.lead_type_phone'),
            },
            {
                value: 'walkIn',
                text: i18n.t('database.lead_type_walk_in'),
            },
            {
                value: 'loyalty',
                text: i18n.t('database.lead_type_loyalty'),
            },
            {
                value: 'renewal',
                text: i18n.t('database.lead_type_renewal'),
            },
            {
                value: 'event',
                text: i18n.t('database.lead_type_event'),
            },
            {
                value: 'sms',
                text: i18n.t('database.lead_type_sms'),
            },
            {
                value: 'dms',
                text: i18n.t('database.lead_type_dms'),
            },
            {
                value: 'webOrder',
                text: i18n.t('database.lead_type_web_order'),
            },
        ];
    }

    static apply(events, filters) {
        if (!filters.length) {
            return events;
        }

        return events.filter(event => {
            let isValid = false;

            filters.forEach(filter => {
                switch (filter) {
                    case 'email':
                        if (get(event, 'lead.lead_type_id') == LeadType.EMAIL) {
                            isValid = true;
                        }
                        break;

                    case 'phone':
                        if (get(event, 'lead.lead_type_id') == LeadType.PHONE) {
                            isValid = true;
                        }
                        break;

                    case 'walkIn':
                        if (get(event, 'lead.lead_type_id') == LeadType.WALK_IN) {
                            isValid = true;
                        }
                        break;

                    case 'loyalty':
                        if (get(event, 'lead.lead_type_id') == LeadType.LOYALTY) {
                            isValid = true;
                        }
                        break;

                    case 'renewal':
                        if (get(event, 'lead.lead_type_id') == LeadType.RENEWAL) {
                            isValid = true;
                        }
                        break;

                    case 'event':
                        if (get(event, 'lead.lead_type_id') == LeadType.EVENT) {
                            isValid = true;
                        }
                        break;

                    case 'sms':
                        if (get(event, 'lead.lead_type_id') == LeadType.SMS) {
                            isValid = true;
                        }
                        break;

                    case 'dms':
                        if (get(event, 'lead.lead_type_id') == LeadType.DMS) {
                            isValid = true;
                        }
                        break;

                    case 'webOrder':
                        if (get(event, 'lead.lead_type_id') == LeadType.WEB_ORDER) {
                            isValid = true;
                        }
                        break;
                }
            });

            return isValid;
        });
    }
}
