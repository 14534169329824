import i18n from '../../plugins/vue-i18n.js';
import { getIconMarkup } from '../../utils/icon.js';
import TaskEventType from '../../entities/TaskEventType.js';
import { useContextStore } from '../../store/modules/context/store.js';

export default class StatusFilter {
    static accountHasGas() {
        return useContextStore().account.client_card_fields.process.gas;
    }

    static getFilters() {
        const filters = [
            {
                value: 'past',
                text: i18n.t('calendar.filters.statusTypes.past'),
            },
            {
                value: 'future',
                text: i18n.t('calendar.filters.statusTypes.future'),
            },
            {
                value: 'completed',
                text: i18n.t('calendar.filters.statusTypes.completed'),
            },
            {
                value: 'uncompleted',
                text: i18n.t('calendar.filters.statusTypes.uncompleted'),
            },
            {
                value: 'uncompletedPast',
                text: i18n.t('calendar.filters.statusTypes.uncompletedPast'),
            },
            {
                value: 'confirmed',
                text: i18n.t('calendar.filters.statusTypes.confirmed'),
            },
            {
                value: 'noShow',
                text: i18n.t('calendar.filters.statusTypes.noShow'),
                icon: getIconMarkup('regular/delete', { class: 'event-icon' }),
            },
            {
                value: 'prepared',
                text: i18n.t('calendar.filters.statusTypes.prepared'),
                icon: getIconMarkup('regular/check-circle-1', { class: 'event-icon' }),
            },
            {
                value: 'notPrepared',
                text: i18n.t('calendar.filters.statusTypes.notPrepared'),
            },
        ];

        if (this.accountHasGas()) {
            filters.push(
                {
                    value: 'withGas',
                    text: i18n.t('calendar.filters.statusTypes.withGas'),
                    icon: getIconMarkup('regular/gas-load', { class: 'event-icon' }),
                },
                {
                    value: 'withoutGas',
                    text: i18n.t('calendar.filters.statusTypes.withoutGas'),
                },
            );
        }

        return filters;
    }

    static apply(events, filters) {
        if (!filters.length) {
            return events;
        }

        return events.filter(event => {
            let isValid = false;

            filters.forEach(filter => {
                switch (filter) {
                    case 'past':
                        if (locale_dt(event.start).isBefore(now())) {
                            isValid = true;
                        }
                        break;

                    case 'future':
                        if (locale_dt(event.start).isAfter(now())) {
                            isValid = true;
                        }
                        break;

                    case 'completed':
                        if (event.status) {
                            isValid = true;
                        }
                        break;

                    case 'uncompleted':
                        if (!event.status && !event.canceled && !event.no_show) {
                            isValid = true;
                        }
                        break;

                    case 'uncompletedPast':
                        if (
                            locale_dt(event.start).isBefore(now()) &&
                            !event.status &&
                            !event.canceled &&
                            !event.no_show
                        ) {
                            isValid = true;
                        }
                        break;

                    case 'confirmed':
                        if (event.confirmed) {
                            isValid = true;
                        }
                        break;

                    case 'noShow':
                        if (event.no_show) {
                            isValid = true;
                        }
                        break;

                    case 'prepared':
                        if (
                            event.task_event_type_id == TaskEventType.DELIVERY &&
                            event.lead.prepared
                        ) {
                            isValid = true;
                        }
                        break;

                    case 'notPrepared':
                        if (
                            event.task_event_type_id == TaskEventType.DELIVERY &&
                            !event.lead.prepared
                        ) {
                            isValid = true;
                        }
                        break;

                    case 'withGas':
                        if (
                            this.accountHasGas() &&
                            event.task_event_type_id == TaskEventType.DELIVERY &&
                            event.lead.gas
                        ) {
                            isValid = true;
                        }
                        break;

                    case 'withoutGas':
                        if (
                            this.accountHasGas() &&
                            event.task_event_type_id == TaskEventType.DELIVERY &&
                            !event.lead.gas
                        ) {
                            isValid = true;
                        }
                        break;
                }
            });

            return isValid;
        });
    }
}
