import { get } from 'lodash-es';
import { globalStore } from '@/pinia/storeHelper.js';
import TaskEventType from '../../entities/TaskEventType.js';

function filterGuests(guest, filter) {
    if (guest.id == filter) {
        return true;
    }

    return globalStore().activeUsers.some(user => {
        return user.children.some(child => {
            return child.id == guest.id;
        });
    });
}

export default class UserFilter {
    static getFilters() {
        return globalStore().activeUsers.map(user => {
            return {
                value: `${user.id}`,
                text: `${user.first_name} ${user.last_name}`,
            };
        });
    }

    static apply(events, filters) {
        const parentAuthUser = globalStore().parentAuthUser;
        const validFilters = filters.filter(userId => {
            return this.getFilters().some(
                filter => filter.value == userId || (userId == parentAuthUser.id && parentAuthUser.isParentOf(filter.value)),
            );
        });

        if (!validFilters.length) {
            return events;
        }

        return events.filter(event => {
            return validFilters.some(filter => {
                return (
                    event.user_id == filter ||
                    get(event, 'user.parent_user_id') == filter ||
                    event.guests.some(guest => filterGuests(guest, filter)) ||
                    (get(event, 'lead.delivered_by_id') == filter && event.task_event_type_id == TaskEventType.DELIVERY) ||
                    (get(event, 'lead.delivered_by.parent_user_id') == filter && event.task_event_type_id == TaskEventType.DELIVERY)
                );
            });
        });
    }
}
