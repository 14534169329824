<template>
    <el-dropdown class="max-w-full" placement="bottom-start" trigger="click">
        <activix-button class="flex items-center max-w-full" :type="hasCurrentView ? 'white' : 'outline'">
            <h1 class="m-0 truncate text-xl font-semibold text-grey-650" v-text="title" />
            <icon class="text-grey-600 ml-4 flex-shrink-0 text-xs" :name="$icons.expand" />
        </activix-button>
        <el-dropdown-menu class="max-w-full" slot="dropdown">
            <router-link :to="getViewLink()">
                <el-dropdown-item :class="{ 'text-blue-500 font-bold': !hasCurrentView }">
                    {{ pageTitle }}
                </el-dropdown-item>
            </router-link>
            <el-dropdown-item
                class="group"
                :divided="index === 0"
                :key="view.id"
                v-for="(view, index) in sortedViews"
            >
                <div class="flex items-baseline">
                    <router-link
                        class="flex-1 pr-4 truncate"
                        :class="
                            hasCurrentView && currentView.id == view.id ? 'text-blue-500 font-bold' : 'text-inherit'
                        "
                        :to="getViewLink(view)"
                    >
                        {{ view.name }}
                        <icon class="text-sm ml-1" name="regular/calendar-sync" v-if="viewHasSubscription(view)" />
                    </router-link>
                    <div
                        class="transition-opacity duration-150 opacity-0 | group-hover:opacity-100"
                        :class="{ 'opacity-100': lgLayout }"
                    >
                        <icon
                            name="regular/pencil-1"
                            class="link-grey-light | text-sm"
                            @click="$emit('update-view', view)"
                            v-if="!isCreatedBySystem(view)"
                        />
                        <icon
                            :name="$icons.trash"
                            class="link-grey-light text-sm ml-2 | hover:text-red-500"
                            @click="$emit('delete-view', view)"
                            v-if="!isCreatedBySystem(view)"
                        />
                    </div>
                </div>
            </el-dropdown-item>
        </el-dropdown-menu>
    </el-dropdown>
</template>

<script>
    import { cloneDeep, get } from 'lodash-es';
    import { mapState } from 'pinia';
    import { sort } from '../utils/index.js';
    import { useGlobalStore } from '../store/store.js';

    export default {
        props: {
            views: {
                type: Array,
                required: true,
            },
            currentView: {
                type: Object,
                default: null,
            },
            selectedView: {
                type: Object,
                default: null,
            },
        },

        computed: {
            ...mapState(useGlobalStore, ['pageTitle']),

            hasCurrentView() {
                return !!this.currentView && !!this.currentView.id;
            },

            sortedViews() {
                const views = cloneDeep(this.views);

                return sort(views, 'name');
            },

            title() {
                if (this.hasCurrentView) {
                    return this.currentView.name;
                }

                return this.pageTitle;
            },
        },

        methods: {
            viewHasSubscription(view) {
                return view.subscription && get(view.subscription, 'recurrence', null) != 'noSubscription';
            },

            isCreatedBySystem(view) {
                return view.system_generated;
            },

            getViewLink(view = null) {
                return {
                    name: this.$route.name,
                    query: {
                        viewId: !view ? null : view.id,
                    },
                };
            },
        },
    };
</script>
