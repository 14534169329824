import { get } from 'lodash-es';
import i18n from '../../plugins/vue-i18n.js';

export default class AutomatedFilter {
    static getFilters() {
        return [
            {
                value: 'yes',
                text: i18n.t('general.yes'),
            },
            {
                value: 'no',
                text: i18n.t('general.no'),
            },
        ];
    }

    static apply(events, filters) {
        if (!filters.length) {
            return events;
        }

        return events.filter(event => {
            let isValid = false;

            filters.forEach(filter => {
                switch (filter) {
                    case 'yes':
                        if (get(event, 'automation_action_id', null) != null) {
                            isValid = true;
                        }
                        break;

                    case 'no':
                        if (get(event, 'automation_action_id', null) == null) {
                            isValid = true;
                        }
                        break;
                }
            });

            return isValid;
        });
    }
}
